import React, {useEffect, useRef} from "react"
import Layout from "../../components/interior/layout-int"
import SEO from "../../components/seo"

const NosotrosPage = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            if (typeof window !== "undefined") {
                window.funciones()
            }
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Politicas de privacidad"/>
            <>
                <section className="parallax bg-extra-dark-gray header-int" data-parallax-background-ratio="0.5"
                         style={{backgroundImage: 'url("/images/lca/header1.jpg")'}}>
                    <div className="opacity-extra-medium bg-extra-dark-gray"/>
                    <div className="container">
                        <div className="header row align-items-stretch justify-content-center small-screen">
                            <div
                                className="col-12  page-title-extra-small text-center d-flex justify-content-center flex-column">
                                <h2 className="text-white alt-font font-weight-500 letter-spacing-minus-1px line-height-50 sm-line-height-45 xs-line-height-30 no-margin-bottom">
                                    Terms and Conditions</h2>
                            </div>
                        </div>
                    </div>
                </section>
                {/* end page title */}
                {/* start section */}
                <section id="down-section">
                    <div className="container">
                        <div className="row align-items-center">

                            <div className="col-12 ">
                                <p>GENERAL. Latam Capital Asesoria e Infraestructura, S.C. ("LCA", "We" or "Us") operates this Internet site
                                    (the "Site") and provides services including, but not limited to, those provided on
                                    its own behalf and on behalf of its affiliates. You, as a user of the Site, are
                                    referred to herein as "you" or "user".</p>

                                <p>The following Terms and Conditions of Use and Acceptable Use Policy explain how you
                                    may use the Site. Our Privacy Policy details how we collect information about you on
                                    the Site, how we use it, and how you may contact us to inquire about such
                                    information. Please click here to view the Privacy Policy. The Terms and Conditions
                                    of Use, the Acceptable Use Policy and the Privacy Policy are collectively referred
                                    to herein as the "Online Terms".</p>

                                <p>The Online Terms are effective as of January 2010 and amended as of July 31, 2011.
                                    Please read the entire Online Terms carefully before using the Site, as the Online
                                    Terms may have been modified since you last used the Site. By using the Site,
                                    including but not limited to downloading materials from the Site, you are agreeing
                                    to be bound by the Online Terms. If you do not agree to the Online Terms, do not
                                    download materials from the Site or use the Site in any way.</p>

                                <p>LCA reserves the right, in its sole discretion, to change, modify, add, or remove any
                                    part of the Online Terms, wholly or partially, at any time. You should review the
                                    Online Terms on a regular basis, as amendments will become effective at the time
                                    they are made. You hereby acknowledge and agree that your continued use of the Site
                                    following any amendment to the Online Terms will constitute your acceptance of such.
                                    We will notify you, either by e-mail, or by posting a notice on the home page of the
                                    Site, of any material amendment to the Online Terms.</p>

                                <p>None of the content of this Site constitutes tax, legal, insurance, or investment
                                    advice. Neither the information nor any opinion contained in this Site constitutes a
                                    solicitation or offer by LCA to buy or sell any securities, futures, options, or
                                    other financial instruments. Decisions made on the basis of information contained in
                                    this Site are the sole responsibility of the visitor.</p>

                                <h6 className="alt-font font-weight-500 text-extra-dark-gray w-90">
                                    TERMS AND CONDITIONS OF USE
                                </h6>

                                <p>LIMITED LICENSE. Subject to your compliance with the Terms and Conditions of Use and
                                    the Acceptable Use Policy, LCA grants you a non-exclusive, non-transferable, limited
                                    right to access and use the Site and the information and materials contained therein
                                    (collectively, the "Materials"). LCA authorizes you to view and download the
                                    Materials solely for your own use, except as otherwise expressly provided herein.
                                    The authorization granted in the preceding sentence (a) does not constitute a
                                    transfer of ownership of the Materials, and (b) is subject to the following
                                    restrictions: (1) you may not remove any notice of or any proprietary notices
                                    contained in the Materials, and (2) you may not modify the Materials in any way or
                                    reproduce or publicly display, perform, or distribute the Materials for any public
                                    or commercial purpose. LCA may terminate the authorization, rights and license
                                    granted above, in its sole discretion and for any or no reason. Upon notice of such
                                    termination by methods including, but not limited to, posting such amendments on the
                                    Site, you agree to immediately destroy copies of all Materials then in your
                                    possession, whether in electronic or any other media.</p>

                                <p>PROPERTY RIGHTS AND TRADEMARKS. You acknowledge and agree that the Site and all
                                    content included on the Site (including, without limitation, all text, images,
                                    photographs, illustrations, multimedia [e.g., combinations of graphics, audio and/or
                                    video], charts, tables, indexes, reports, data, software, HTML code and screens) are
                                    owned by, licensed to, or otherwise used with permission by LCA and its business
                                    partners and data providers, and are subject to the intellectual property laws of
                                    the United States and other countries, including laws governing patents, copyrights,
                                    trade secrets, trade names, trademarks, service marks, moral rights, know-how and
                                    any other similar intangible rights or assets. Any information collected, displayed,
                                    stored, and archived on the Site and our systems is the property of LCA, our
                                    affiliates or our business partners. Unauthorized use of any trademark, including
                                    reproduction, imitation, infringement, dilution, or deceptive or misleading uses, is
                                    prohibited under the trademark laws of the United States. The trademarks may not be
                                    used in connection with any product or service not sourced from LCA, in any manner
                                    that is likely to cause confusion among users, or in any manner that disparages or
                                    discredits LCA. All other trademarks featured on the Site are the property of their
                                    respective owners.</p>

                                <p>INFORMATION PROVIDED BY YOU. Except for certain personally identifiable information
                                    (as described in our Privacy Policy), LCA does not want you to provide, and you
                                    shall not provide, through the Site any information that you consider to be
                                    confidential or proprietary to you or anyone else. If you submit any such
                                    information to us, you will lose all rights to such information, and you agree that
                                    any such information that you or an individual acting on your behalf provides to LCA
                                    through the Site will not be considered confidential or proprietary.</p>

                                <p>INFORMATION FOR INFORMATIONAL PURPOSES ONLY. The Materials are provided for
                                    informational purposes only and do not constitute an offer to sell or the
                                    solicitation of an offer to buy any securities or any type of insurance product.
                                    LCA's products and services are subject only to the terms of the documentation
                                    accompanying the rendering of any such products and services and you should refer to
                                    such documentation for complete information thereon. Not all products or services
                                    described on this Site are available in all jurisdictions or to all prospective
                                    customers or investors. None of the contents of this Site is intended to constitute,
                                    nor shall it be deemed to constitute, investment advice or an offer or solicitation
                                    of any kind, including but not limited to information provided on this Site in any
                                    jurisdiction or to any prospective client or investor where any information relating
                                    to products and services could be construed as an offer or solicitation that does
                                    not otherwise qualify or is exempt from regulation.</p>

                                <p>CONTENT AND OPERATION OF THE SITE. The information contained in the Site is subject
                                    to change without notice to you or any other person. LCA assumes no obligation to
                                    maintain the information in the Site or to ensure that such information is the most
                                    current information available. LCA may terminate, modify, suspend, or discontinue
                                    any aspect of the Site, including the availability of any feature of the Site, at
                                    any time. LCA may also impose limits, for any or no reason, on certain features and
                                    services, or completely or partially restrict your access to the Site without
                                    providing prior notice or liability.</p>

                                <p>REQUESTS FOR INFORMATION. Except in connection with responding to withdrawal notices
                                    or responding to questions regarding personally identifiable information (as
                                    described in our Privacy Policy), LCA is under no obligation to respond to any
                                    requests for information or other requests you send to LCA, and LCA expressly
                                    disclaims any responsibility to respond or not respond to such requests. LCA also
                                    expressly disclaims any responsibility to send you investor alerts or information at
                                    your request and disclaims any responsibility for the content of such investor
                                    alerts and information.</p>

                                <p>DISCLAIMER OF WARRANTIES. THIS SITE IS PROVIDED TO YOU "AS IS" WITHOUT WARRANTIES OF
                                    ANY KIND, EITHER EXPRESS OR IMPLIED. YOU EXPRESSLY UNDERSTAND AND AGREE THAT LCA
                                    MAKES NO CLAIMS OR WARRANTIES OF ANY KIND REGARDING THE RELIABILITY, TIMELINESS,
                                    QUALITY, SUITABILITY, AVAILABILITY, ACCURACY OR COMPLETENESS OF THE SITE OR ANY
                                    CONTENT CONTAINED THEREIN. LCA MAKES NO REPRESENTATIONS OR WARRANTIES, AND HEREBY
                                    DISCLAIMS, ANY REPRESENTATIONS OR WARRANTIES THAT: (A) YOUR USE OF THE SITE WILL BE
                                    TIMELY, UNINTERRUPTED OR ERROR-FREE OR THAT IT WILL WORK AS INTENDED WITH ANY OTHER
                                    HARDWARE, SOFTWARE, SYSTEM OR DATA, (B) THE SITE WILL MEET YOUR REQUIREMENTS OR
                                    EXPECTATIONS, (C) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION OR OTHER
                                    MATERIAL OBTAINED BY YOU THROUGH THE SITE WILL MEET YOUR REQUIREMENTS OR
                                    EXPECTATIONS, (D) ERRORS OR DEFECTS WILL BE CORRECTED, AND (E) YOUR USE OF THE SITE,
                                    OR THE SERVER(S) THAT MAKE THE SITE AVAILABLE, ARE FREE OF WORMS, TROJAN HORSES,
                                    VIRUSES OR OTHER HARMFUL ELEMENTS. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
                                    LAW, WE DISCLAIM ALL CONDITIONS, REPRESENTATIONS AND WARRANTIES OF ANY KIND, DIRECT
                                    OR INDIRECT, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, BUT NOT
                                    LIMITED TO, WARRANTIES OF TITLE, IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS
                                    FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF THIRD-PARTY RIGHTS, WITH RESPECT TO
                                    ANY PRODUCT OR SERVICE PROVIDED HEREIN. NO INFORMATION OR ADVICE, WHETHER SPOKEN OR
                                    WRITTEN, GIVEN BY US OR ANY OF OUR REPRESENTATIVES SHALL CREATE ANY WARRANTY OF ANY
                                    KIND WITH RESPECT TO THE SITE.</p>

                                <p>LIMITATION OF LIABILITY. IN NO EVENT WILL WE OR ANY OF OUR AFFILIATES, OR ANY OF OUR
                                    OR OUR AFFILIATES' EXECUTIVE OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, AGENTS,
                                    REPRESENTATIVES, PARTNERS OR LICENSORS (THE "LCA PARTIES") BE LIABLE (1) FOR ANY
                                    LOSS OR DAMAGE ARISING OUT OF YOUR FAILURE TO COMPLY WITH THE TERMS AND CONDITIONS
                                    OF USE OR ACCEPTABLE USE POLICY, (2) UNDER ANY THEORY OF LIABILITY (INCLUDING,
                                    WITHOUT LIMITATION, NEGLIGENCE AND STRICT LIABILITY) FOR ANY DIRECT, INDIRECT,
                                    INCIDENTAL, SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGES, OR LOST PROFITS OR REVENUES
                                    (WHETHER IN AN ACTION BASED IN CONTRACT, TORT, PRODUCT LIABILITY, STATUTE OR
                                    OTHERWISE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE)
                                    ARISING OUT OF (A) YOUR USE OF THE SITE, INCLUDING WITHOUT LIMITATION ANY LOSS OR
                                    DAMAGE RESULTING FROM ANY RELIANCE PLACED ON ANY INFORMATION ACCESSED THROUGH OR
                                    CONTAINED IN THE SITE, OR ANY DELAY, INACCURACY, ERROR OR OMISSION IN ANY SUCH
                                    INFORMATION; (B) ANY TRANSACTION ENTERED INTO THROUGH OR FROM THE SITE; (C) YOUR
                                    FAILURE TO USE THE SITE OR THE INFORMATION CONTAINED THEREIN FOR ANY REASON,
                                    INCLUDING BUT NOT LIMITED TO COMMUNICATIONS FAILURE OR ANY OTHER FAILURE RELATED TO
                                    THE TRANSMISSION OR DELIVERY OF ANY INFORMATION ACCESSED ON OR THROUGH THE SITE; (D)
                                    THE DELETION, AMENDMENT, DESTRUCTION, DAMAGE, LOSS OR FAILURE TO STORE ANY
                                    INFORMATION; (E) THE USE OF ANY PRODUCT OR SERVICE OBTAINED ON OR THROUGH THE SITE;
                                    (F) UNAUTHORIZED ACCESS TO THE SITE AND UNAUTHORIZED MODIFICATION OF YOUR
                                    TRANSMISSIONS OR DATA; (G) THE STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SITE;
                                    OR (H) ANY OTHER MATTER RELATING TO THE SITE OR YOUR USE OF THE SITE.</p>

                                <p>SINCE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE
                                    LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, IN
                                    SUCH JURISDICTIONS OUR TOTAL LIABILITY SHALL BE LIMITED TO THE EXTENT PERMITTED BY
                                    THE LAWS OF SUCH JURISDICTION. IN NO EVENT SHALL OUR TOTAL LIABILITY TO YOU FOR ALL
                                    LOSSES OR DAMAGES WITH RESPECT TO THE SITE EXCEED THE AMOUNT OF $50.00. </p>

                                <p>INDEMNIFICATION. You agree to indemnify, hold harmless and, at our option, defend any
                                    of the LCA Parties against all damages, costs, liabilities, actions, judgments,
                                    penalties, expenses, obligations, losses, and claims (including without limitation
                                    attorneys' fees and expenses) relating to or arising out of (a) any breach by you of
                                    these Terms and Conditions of Use and/or the Acceptable Use Policy, and/or (b) any
                                    use of the Site by you, by any person authorized by you, or by any person using the
                                    Site with your password, whether or not such use was authorized by you.</p>

                                <p>THIRD-PARTY SITES. This Site includes content provided by third parties and may
                                    include links to websites operated by parties other than LCA, including our business
                                    partners, information providers, advertisers and other third parties. Such content
                                    and links are provided solely as a convenience to you. LCA does not endorse or
                                    assume responsibility for any information, advertising, services, or products made
                                    available by third parties on or through their websites, or for any privacy
                                    practices or terms of use of such third parties. If you use such content or such
                                    sites, you will leave this Site. If you choose to browse content on a third-party
                                    site, you do so at your own risk and it is your sole and exclusive responsibility to
                                    take all protective measures necessary to guard against viruses or other harmful or
                                    destructive elements, including but not limited to those that may cause system
                                    failures or malfunctions, upon transfer to other sites. We reserve the right to
                                    remove content provided by third parties and to remove any link to a third-party
                                    site at any time. Content provided by a third party, or links to a third-party site,
                                    do not imply that LCA sponsors, endorses, is affiliated or associated with, or is
                                    legally authorized to use any trademark, trade name, logo or copyright symbol
                                    displayed in such content or accessible through such links, or that any third party
                                    providing content or operating a linked site is authorized to use any trademark,
                                    trade name, logo or copyright symbol of LCA or any of its affiliates or
                                    subsidiaries.</p>

                                <p>WITHOUT LIMITING THE FOREGOING, YOU AGREE THAT IF YOU USE INFORMATION, SERVICES OR
                                    PRODUCTS FROM A THIRD PARTY OFFERED ON AN INTERNET SITE LINKED TO OR OTHERWISE
                                    REFERENCED ON OR THROUGH THE SITE, SUCH THIRD PARTY SHALL BE SOLELY RESPONSIBLE FOR
                                    SUCH INFORMATION, SERVICES OR PRODUCTS, AND YOU HEREBY RELEASE ALL LCA PARTIES FROM
                                    ANY AND ALL CLAIMS, LIABILITIES AND DAMAGES (INCLUDING, WITHOUT LIMITATION, DIRECT,
                                    INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGES) OF EVERY KIND AND
                                    NATURE, KNOWN AND UNKNOWN, SUSPECTED AND UNSUSPECTED, DISCLOSED AND UNDISCLOSED,
                                    ARISING OUT OF OR RELATING IN ANY WAY TO SUCH INFORMATION, SERVICE OR PRODUCT, ANY
                                    TRANSACTION BETWEEN YOU AND SUCH THIRD PARTY AND ANY DISPUTE BETWEEN YOU AND SUCH
                                    THIRD PARTY.</p>

                                <p>COPYRIGHT CLAIMS AND DESIGNATED AGENT. We respect the intellectual property rights of
                                    others and require that persons and entities using the Site do the same. In our sole
                                    discretion, we reserve the right to terminate access to the Site of any person or
                                    entity that we believe is or may be responsible for copyright infringement. The
                                    Digital Millennium Copyright Act (DMCA) provides a mechanism for notifying service
                                    providers regarding claims of unauthorized use of copyrighted materials. Pursuant to
                                    the DMCA, a complaint should be sent to the service provider's Designated Agent. If
                                    you believe in good faith that LCA should receive notification of possible online
                                    copyright infringement involving the Site, please notify LCA's Designated Agent
                                    using the contact information below:</p>


                                <p>Torre Optima 1 <br/>
                                    Av. Paseo de las Palmas No. 405-404 <br/>
                                    Lomas de Chapultepec <br/>
                                    11000, Mexico City, Mexico <br/>
                                    52-55-5202-6080 <br/>
                                    o a: <a href="mailto:info@latamcapitaladvisors.com"
                                            target="_blank">Info@Latamcapitaladvisors.com</a></p>

                                <p>Please note that, in order to be effective, your notice of claim must comply with the
                                    detailed requirements set forth in the DMCA. You are encouraged to review these
                                    requirements (see 17 U.S.C. Section 512(3)) before submitting your complaint. Please
                                    do not send any other notices or communications to the Designated Agent, which has
                                    been appointed solely for the purpose of receiving notifications of copyright claims
                                    under the DMCA.</p>

                                <p>USERS OUTSIDE THE UNITED STATES AND CHOICE OF LAW. This Site is controlled, operated,
                                    and managed by us from our U.S. offices and all information you submit to us will be
                                    stored on servers located in the United States. LCA makes no claim that the
                                    Materials are appropriate or available for use in locations outside the United
                                    States, and access to the Materials in territories where their contents are illegal
                                    is prohibited. If you access this Site from a location outside the United States,
                                    you are responsible for compliance with all local laws. These Terms and Conditions
                                    of Use and the Acceptable Use Policy shall be governed by the laws of the State of
                                    New York, without regard to its principles or rules of conflicts of laws to the
                                    extent such principles or rules are not mandatorily applicable by statute and
                                    require or permit the application of the laws of another jurisdiction. Without
                                    limiting LCA's right to bring actions against you in any jurisdiction, you further
                                    agree to submit to the non-exclusive jurisdiction of and ratify and agree that
                                    proper jurisdiction shall lie in the federal and state courts located in New York
                                    County, New York or Westchester County, White Plains, New York, with respect to any
                                    claim, action or proceeding arising out of or relating to this Agreement. </p>

                                <p>PASSWORD SECURITY AND NOTIFICATION. Certain sections of the Site are password
                                    protected or require a login and are restricted to authorized users only. You may
                                    not gain unauthorized access to such sections of the Site, or any other protected
                                    material or information, through any method not intentionally made available by LCA
                                    for your specific use. If you have a Personal Identification Number (PIN) for access
                                    to non-public sections of the Site, you are solely responsible for all activities
                                    that occur in connection with your PIN. Accordingly, you must take all reasonable
                                    steps necessary to protect the confidentiality of your PIN. Please notify LCA
                                    immediately if you become aware of any disclosure, loss, theft, or unauthorized use
                                    of your PIN.</p>

                                <p>PASSWORD-PROTECTED SECTIONS OF THE SITE: With respect to password-protected sections
                                    of the Site, these Terms shall be superseded by any law or regulatory rule or any
                                    contract or agreement between LCA and the party accessing the Site. LCA takes no
                                    responsibility, and will assume no liability, for any Material placed in
                                    password-protected sections of the Site.</p>

                                <p>ADDITIONAL TERMS. These Online Terms constitute the entire agreement between LCA and
                                    you with respect to your use of the Site. Any cause of action you may have with
                                    respect to use of the Site must be commenced within one (1) year after the cause of
                                    action arises, and you hereby waive any right, whether statutory or otherwise, to
                                    any further extension of time beyond such one (1) year period. If for any reason a
                                    competent court finds any provision of these Online Terms to be unenforceable, the
                                    remainder of the provisions set forth herein shall continue in full force and
                                    effect.</p>

                                <p>Torre Optima 1 <br/>
                                    Av. Paseo de las Palmas No. 405-404 <br/>
                                    Lomas de Chapultepec <br/>
                                    11000, Mexico City, Mexico <br/>
                                    52-55-5202-6080 <br/>
                                    o a: <a href="mailto:info@latamcapitaladvisors.com"
                                            target="_blank">Info@Latamcapitaladvisors.com</a></p>


                                <p>Where possible, all complaints should include details that may assist LCA in
                                    investigating and resolving the complaint (e.g., a copy of the offending
                                    material).</p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </Layout>
    )
}

export default NosotrosPage
